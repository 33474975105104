var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Height", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the height of the loading bar with the property height"
        ),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { color: "primary", icon: "new_releases", active: "true" },
        },
        [
          _c("p", [
            _vm._v("By default the property "),
            _c("code", [_vm._v("height")]),
            _vm._v(" is "),
            _c("strong", [_vm._v("5")]),
            _vm._v("(5px)"),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-progress", {
            attrs: { height: 2, percent: 100, color: "primary" },
          }),
          _c("vs-progress", {
            attrs: { height: 4, percent: 80, color: "warning" },
          }),
          _c("vs-progress", {
            attrs: { height: 8, percent: 60, color: "danger" },
          }),
          _c("vs-progress", {
            attrs: { height: 12, percent: 40, color: "success" },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-progress :height="2" :percent="100" color="primary"></vs-progress>\n<vs-progress :height="4" :percent="80" color="warning"></vs-progress>\n<vs-progress :height="8" :percent="60" color="danger"></vs-progress>\n<vs-progress :height="12" :percent="40" color="success"></vs-progress>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }