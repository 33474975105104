var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "progress-demo" } },
    [_c("progress-color"), _c("progress-interminate"), _c("progress-height")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }