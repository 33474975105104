var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [_vm._v("Nice colors for your progress bar")]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-progress", { attrs: { percent: 100, color: "primary" } }),
          _c("vs-progress", { attrs: { percent: 90, color: "warning" } }),
          _c("vs-progress", { attrs: { percent: 75, color: "danger" } }),
          _c("vs-progress", { attrs: { percent: 60, color: "success" } }),
          _c("vs-progress", { attrs: { percent: 45, color: "dark" } }),
          _c("vs-progress", {
            attrs: { percent: 30, color: "rgb(164, 69, 15)" },
          }),
          _c("vs-progress", { attrs: { percent: 15, color: "#24c1a0" } }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-progress :percent="100" color="primary"></vs-progress>\n<vs-progress :percent="90" color="warning"></vs-progress>\n<vs-progress :percent="75" color="danger"></vs-progress>\n<vs-progress :percent="60" color="success"></vs-progress>\n<vs-progress :percent="45" color="dark"></vs-progress>\n<vs-progress :percent="30" color="rgb(164, 69, 15)"></vs-progress>\n<vs-progress :percent="15" color="#24c1a0"></vs-progress>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }