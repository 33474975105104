var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Interminate", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can have a progress bar with indeterminate value with the property indeterminate"
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [_c("vs-progress", { attrs: { indeterminate: "", color: "primary" } })],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-progress indeterminate color="primary"></vs-progress>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }